import React, { useRef, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
function BlogSlider() {
  const slider = useRef();
  const [step, setStep] = useState(0);
  const scroll = (scrollOffset) => {
    slider.current.scrollLeft += scrollOffset;
  };
  const BlogElements = [
    {
      img:
        "https://blog.trato.io/wp-content/uploads/2022/03/robynne-hu-HOrhCnQsxnQ-unsplash.jpg",
      title: "Los beneficios medioambientales de apostar por un mundo digital",
      text:
        "Al enfrentarnos ante la decisión de continuar con una manera de trabajo tradicional, utilizando papel, imprimiendo cada documento, y teniendo que almacenarlo, u optar",
      link:
        "https://blog.trato.io/los-beneficios-medioambientales-de-apostar-por-un-mundo-digital/",
    },

    {
      img:
        "https://blog.trato.io/wp-content/uploads/2021/07/blake-connally-k03ornGON08-unsplash-scaled-e1626362916795.jpg",
      title: "La digitalización como medio de protección al...",
      text:
        "Uno de los principales problemas que la sociedad ha tomado como prioridad de atención es la falta de protección al ambiente...",
      link:
        "https://blog.trato.io/la-digitalizacion-como-medio-de-proteccion-al-ambiente/",
    },
    {
      img:
        "https://blog.trato.io/wp-content/uploads/2022/02/pexels-canva-studio-3194519.jpg",
      title: "El impacto positivo de la transformacion digital...",
      text:
        "De acuerdo con el índice de Transformación Digital de Dell Technologies México, durante 2020, el...",
      link: "https://blog.trato.io/transformacion-digital-medio-ambiente/",
    },
    {
      img:
        " https://blog.trato.io/wp-content/uploads/2022/04/noah-buscher-x8ZStukS2PM-unsplash.jpg",
      title: "¿Qué significa la responsabilidad ambiental?",
      text:
        "Ante la crisis de recursos que vivimos hoy en día, sean de falta de agua, contaminación, o desperdicio, es indispensable que las empresas...",
      link: "https://blog.trato.io/que-significa-la-responsabilidad-ambiental/",
    },

    {
      img:
        "https://blog.trato.io/wp-content/uploads/2022/03/roonz-nl-svhi9yym29o-unsplash.jpg",
      title: "Digitalización en las empresas: ¿Qué significa...",
      text:
        "Vivimos en la era de conenctividad, con la vemos que como humanidad estamos enfrentando problemas globales que van más alla...",
      link:
        "https://blog.trato.io/digitalizacion-en-las-empresas-que-significa-para-el-medio-ambiente/",
    },
  ];
  const [elementsToRender, setElementsToRender] = useState(BlogElements);
  useEffect(() => {
    console.log(elementsToRender);
  }, [elementsToRender]);

  useEffect(() => {
    console.log(step);

    console.log(elementsToRender.length);
    if (step != 0) {
      setElementsToRender(elementsToRender.concat(BlogElements));
    }
  }, [step]);

  return (
    <div
      className="selloAmbientalContainer container--gray container--9--main"
      style={{
        position: "relative",
      }}
    >
      {BlogElements.length > 3 && (
        <div
          className="slideArrow--1"
          onClick={() => {
            scroll(-900);
            setStep(step - 1);
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} color="#ffffff" />
        </div>
      )}
      <div
        className="selloAmbiental_Sub_Container sello--container--9"
        ref={slider}
      >
        {elementsToRender.map((item) => (
          <div className="container--9--children">
            <div style={{ backgroundImage: `URL(${item.img})` }}></div>
            <h3 className="blog--title--text--green">{item.title}</h3>
            <p className="normal--text--black">{item.text}</p>
            <a href={item.link} className={"link--text--green"}>
              Leer Más
            </a>
          </div>
        ))}
      </div>

      {BlogElements.length > 3 && (
        <div
          className="slideArrow--2"
          onClick={() => {
            scroll(900);
            setStep(step + 1);
          }}
        >
          <FontAwesomeIcon icon={faArrowRight} color="#ffffff" />
        </div>
      )}
    </div>
  );
}

export default BlogSlider;
