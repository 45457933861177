import React from "react";
import { LocalizedLink } from "gatsby-theme-i18n";
import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo_desktop from "../images/trato-logo.svg";
import {
  faWhatsapp,
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import logo from "../images/Logo Trato.svg";

import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import footerImage from "../images/insignia 100 PRO 2022_color.png"
export const WhatsMessage = (lang) => {
  const translation = {
    es: "Hola, quiero empezar a gestionar mis contratos",
    en: "Hello, I want to start managing my contracts",
    pt: "Hello, I want to start managing my contracts",
  };
  const numberPhone = {
    es: "5215581079280",
    en: "+443330905249",
    pt: "3330905249",
  };
  return lang === "en"
    ? `tel:${numberPhone[lang]}`
    : `https://wa.me/${numberPhone[lang]}?text=${encodeURIComponent(
        translation[lang]
      )}`;
};

const iconCall = {
  es: faWhatsapp,
  en: faPhoneAlt,
  pt: faPhoneAlt,
};

const toMail = {
  es: "hola@trato.io",
  en: "hello@trato.io",
};

function NewFooter({ locale }) {
  const { t } = useTranslation("home");
  const images = useStaticQuery(imagesQuery);

  return (
    <div>
      <section className="newFooter">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 mb-sm-3">
              <img
                alt="Trato Logo"
                src={logo}
                width="120"
                height="30"
                style={{ marginBottom: "5px" }}
              />
              <p className="text-white newFont mt-1">
                {t("footerSection.extra")}
              </p>
              <p className="text-white">
                {t("footerSection.contact")}
                <OutboundLink
                  href={"mailto:" + toMail[locale]}
                  target="_blank"
                  className="mb-2 text-white font-weight-bold"
                >
                  {" "}
                  {toMail[locale]}
                </OutboundLink>
              </p>
              <ul className="p-0">
                <li className="list-inline-item">
                  <a
                    href={WhatsMessage(locale)}
                    target="_blank"
                    className="lead text-white"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={iconCall[locale]} color="#ffffff" />
                  </a>
                </li>
                <li className="list-inline-item ml-2">
                  <a
                    href="https://www.facebook.com/tratoapp"
                    target="_blank"
                    className="lead text-white"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebook} color="#ffffff" />
                  </a>
                </li>
                <li className="list-inline-item ml-2">
                  <a
                    href="https://www.linkedin.com/company/contratosapp/"
                    target="_blank"
                    className="lead text-white"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedin} color="#ffffff" />
                  </a>
                </li>
                <li className="list-inline-item ml-2">
                  <a
                    href="https://twitter.com/TratoApp"
                    target="_blank"
                    className="lead text-white"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faTwitter} color="#ffffff" />
                  </a>
                </li>
                <li className="list-inline-item ml-2">
                  <a
                    href={"mailto:" + toMail[locale]}
                    className="lead text-white"
                  >
                    <FontAwesomeIcon icon={faEnvelope} color="#ffffff" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-7 col-md-12 offset-lg-1">
              <div className="row">
                <div className="col-md-4 mb-sm-3">
                  <h5 className="text-white font-weight-bold mb-2">
                    {t("footerSection.heading2")}
                  </h5>
                  <ul className="list-unstyled">
                    <li className="mb-0">
                      <LocalizedLink to="/" className="text-white">
                        {t("footerSection.footerLink1")}
                      </LocalizedLink>
                    </li>

                    <li className="mb-0">
                      <a href="https://blog.trato.io" className="text-white ">
                        Blog
                      </a>
                    </li>
                    <li className="mb-0">
                      <LocalizedLink to="/contacto" className="text-white">
                        {t("footerSection.footerLink5")}
                      </LocalizedLink>
                    </li>
                    <li className="mb-0 mt-2">
                      <LocalizedLink to="/firma-electronica">
                        <span style={{ color: "#ffffff" }}>
                          Todo sobre Firma Electrónica
                        </span>
                      </LocalizedLink>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 mb-sm-3">
                  <h5 className="text-white font-weight-bold mb-2">
                    {t("footerSection.heading3")}
                  </h5>
                  <ul className="list-unstyled">
                    <li className="mb-0">
                      <LocalizedLink to="/templates" className="text-white">
                        {t("footerSection.footerLink7")}
                      </LocalizedLink>
                    </li>
                    <li className="mb-0">
                      <LocalizedLink to="/trato-clm" className="text-white">
                        Trato CLM
                      </LocalizedLink>
                    </li>
                    <li className="mb-0">
                      <LocalizedLink to="/trato-starter" className="text-white">
                        Trato Starter
                      </LocalizedLink>
                    </li>
                    <li className="mb-0">
                      <LocalizedLink
                        to="/legal-advisor"
                        className="text-white "
                      >
                        Legal Advisor
                      </LocalizedLink>
                    </li>

                    {/* <li className="">Blockchain</li> */}
                  </ul>
                </div>
                <div className="col-md-4 mb-sm-3">
                  <h5 className="text-white font-weight-bold mb-2">
                    {t("footerSection.heading4")}
                  </h5>
                  <ul className="list-unstyled">
                    <li className="mb-2">
                      <LocalizedLink to="/faq" className="text-white ">
                        {t("footerSection.footerLink10")}
                      </LocalizedLink>
                    </li>
                    <li>
                      <a
                        href="https://www.pronetwork.mx/magazine/las-100-pro-reconoce-a-las-proximas-grandes-empresas-de-mexico/"
                        target="_blank"
                        className="lead text-white"
                        rel="noopener noreferrer"
                      >
                        {/* <Img
                          fixed={images.emblema2020.childImageSharp.fixed}
                          alt=""
                          width="150px"
                        /> */}
                        <img
                        src={footerImage}
                        width={150}
                        alt="Las100Pro"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default NewFooter;

const socialQuery = graphql`
  query SocialQuery {
    dataYaml {
      facebook
      email
      linkedin
      twitter
    }
  }
`;

const imagesQuery = graphql`
  query ImageQuery {
    emblema2020: file(relativePath: { eq: "emblema2020.png" }) {
      childImageSharp {
        fixed(width: 150, height: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
