import React, { useEffect } from "react";
import "../scss/selloAmbiental.scss";
import { Helmet } from "react-helmet";
import Sello1 from "../components/selloAmbiental/sello1";
import Sello2 from "../components/selloAmbiental/Sello2";
import Sello3 from "../components/selloAmbiental/Sello3";
import Sello4 from "../components/selloAmbiental/sello4";
import Sello5 from "../components/selloAmbiental/sello5";
import Sello6 from "../components/selloAmbiental/sello6";
import Sello7 from "../components/selloAmbiental/sello7";
import Sello8 from "../components/selloAmbiental/sello8";
import FormSection from "../components/selloAmbiental/FormSection";
import BlogSlider from "../components/selloAmbiental/BlogSlider";
import DemoModal from "../components/SelloDemoModal";
import useScript from "../hooks/useScript";
import { useLocalization } from "gatsby-theme-i18n";
import NavBar from "../components/eFirma/NavBar";
import Footer from "../components/newIndexFooter";
import SubFooter from "../components/SubFooter";
import CookiesConsent from "../components/CookiesConsent";
function SelloAmbiental() {
  const [pageY, setPageY] = React.useState(0);

  const [mobileNav, setMobileNav] = React.useState(false);
  const { defaultLang, locale, localizedPath } = useLocalization();
  const [pageLoaded, setPageLoaded] = React.useState(false);
  const [modal, setModal] = React.useState(false);

  const handleModal = () => {
    setModal(!modal);
  };
  useEffect(() => {
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    const options = { passive: true }; // options must match add/remove event
    const scroll = (event) => {
      const { pageYOffset, scrollY } = window;
      setPageY(scrollY);
    };
    document.addEventListener("scroll", scroll, options);
    // remove event on unmount to prevent a memory leak
    () => document.removeEventListener("scroll", scroll, options);
  }, []);
  const [hasAccepted, setHasAccepted] = React.useState(false);
  useScript({
    isDisabled: !hasAccepted,
    src: "https://leadbooster-chat.pipedrive.com/assets/loader.js",
  });
  useEffect(() => {
    const cookies = Boolean(localStorage.getItem("hasAccepted")) || false;
    if (cookies) {
      setHasAccepted(cookies);
    }
  }, []);
  return (
    <div className="sello-ambiental--main">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Obtén tu Sello Ambiental, toma acción para la recuperación y protección del medio ambiente digitalizando tus documentos y donando a la causa"
        />
        <title>
          Sello Ambiental, certifica a tu empresa en medio ambiente - TRATO
        </title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      {pageLoaded && (
        <DemoModal modal={modal} handleModal={handleModal} type="sello-trato" />
      )}
      {pageY > 560 && (
        <NavBar
          className={"eFirma--navBarSticky"}
          isOpen={mobileNav}
          toggle={() => setMobileNav(!mobileNav)}
          handleClose={() => setMobileNav(false)}
        />
      )}
      <Sello1 handleModal={handleModal} />
      <Sello2 />
      <Sello3 />
      <Sello4 handleModal={handleModal} />
      <Sello5 handleModal={handleModal} />
      <Sello6 handleModal={handleModal} />
      <FormSection />
      <Sello7 handleModal={handleModal} />
      <Sello8 />
      <BlogSlider />
      <Footer locale={locale} />
      <SubFooter />
      {!hasAccepted && (
        <CookiesConsent handleContinue={() => setHasAccepted(true)} />
      )}
    </div>
  );
}

export default SelloAmbiental;
