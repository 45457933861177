import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import pic1 from "../../images/sello/pic1-9.webp";
function Sello8() {
  return (
    <div className="selloAmbientalContainer container--green">
      <div
        className="selloAmbiental_Sub_Container sello--container--8"
        style={{ backgroundImage: `URL(${pic1})` }}
      >
        <div>
          <h2>
            <span className="span--text--white">LA DIGITALIZACIÓN PARA LA</span>
            <span className="title--text--white">
              PROTECCIÓN DEL <br /> MEDIO AMBIENTE
            </span>
          </h2>

          <p className="normal--text--white">
            El sector privado debe comprometerse a tomar medidas que prevengan
            el cuidado del planeta, tomando acciones para reducir el impacto
            negativo que puedan causar sus negocios al ambiente. <br />
            <br /> La reducción o nulo uso de papel, evitar envíos y
            movilización innecesaria, así como las donaciones a asociaciones con
            campañas de reforestación en México, ayudarán a la preservación del
            ambiente. <br />
            <br /> Los valores del Sello Ambiental de TRATO, dan sustento a las
            acciones realizadas para la protección al ambiente, así como motivan
            a los líderes para generar sinergia a favor del cuidado y la
            protección del ambiente.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Sello8;
