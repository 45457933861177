import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import pic1 from "../../images/sello/pic1-5.webp";
import pic2 from "../../images/sello/pic2-5.webp";
function Sello5({ handleModal }) {
  return (
    <div className="selloAmbientalContainer container--white">
      <div className="selloAmbiental_Sub_Container sello--container--5">
        <div className="children--1">
          <span className="span--text--green">Sobre Nosotros</span>
          <h2 className="title--text--green">
            IDEOLOGÍA <br /> TRATO
          </h2>
          <p className="normal--text--black">
            En TRATO entendemos que la mejor forma de proteger y conservar los
            recursos es promover el uso de la tecnología y la digitalización de
            procesos para reducir el consumo de energía y otros recursos. <br />
            <br />
            En la reunión del COP26 (nov 2021), se estableció que las
            tecnologías digitales, ayudarán a reducir el daño al medio ambiente
            en un mínimo de 15% para 2030; la iniciativa privada del sello
            ambiental, con el uso de blockchain y la tecnología de TRATO, pueden
            ayudar a cumplir esta meta.
          </p>

          <button className="sello--button" onClick={handleModal}>
            DIGITALIZAR MIS DOCUMENTOS
          </button>
        </div>
        <div className="children--2">
          <img src={pic1} alt="" width={300} height={600} />
          <img src={pic2} alt="" width={300} height={600} />
        </div>
      </div>
    </div>
  );
}

export default Sello5;
