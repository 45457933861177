import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import pic1 from "../../images/sello/pic1-4.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
function Sello4({ handleModal }) {
  const [step, setStep] = React.useState(1);
  return (
    <div className="selloAmbientalContainer container--green">
      <div
        className="selloAmbiental_Sub_Container sello--container--4"
        style={{ backgroundImage: `URL(${pic1})` }}
      >
        <div
          onClick={() => {
            if (step === 1) {
              setStep(2);
            }
            if (step === 2) {
              setStep(1);
            }
          }}
          className="slideArrow--1"
        >
          <FontAwesomeIcon icon={faArrowLeft} color="#ffffff" />
        </div>
        {step === 1 && (
          <div className="text--content--sello toggleOpacity">
            <span className="span--text--white">¿QUÉ ES UN</span>
            <h2 className="title--text--white"> SELLO AMBIENTAL?</h2>
            <p className="normal--text--white">
              A partir del compromiso adoptado por México, las autoridades y las
              empresas están obligadas a tomar medidas para promover la
              protección al ambiente y mitigar los efectos del cambio climático.
              <br />
              <span style={{ fontWeight: "bold" }}>TRATO</span> desarrolló el
              sello ambiental para certificar las buenas prácticas de las
              empresas para mitigar el cambio climático. Los valores del sello
              dan sustento a las acciones realizadas para la protección al
              ambiente, así como motivan a los líderes para generar sinergia a
              favor del cuidado y la protección del ambiente.
            </p>
            <button className="sello--button" onClick={handleModal}>
              OBTENER EL SELLO AMBIENTAL
            </button>
          </div>
        )}
        {step === 2 && (
          <div className="text--content--sello toggleOpacity2">
            <span className="span--text--white">NUESTRO</span>
            <h2 className="title--text--white"> SELLO AMBIENTAL</h2>
            <p className="normal--text--white">
              Creemos que la mejor forma de proteger y conservar los recursos es
              promover el uso de la tecnología y la digitalización de procesos
              para reducir el consumo de energía y otros recursos; así como el
              apoyo real a iniciativas que toman acción por la conservación de
              nuestra tierra, creando un futuro sostenible para las próximas
              generaciones.
              <br />
              Nuestro sello ambiental será un distintivo a las empresas que han
              decidido actuar en pro del medio ambiente y el futuro.
            </p>
            <button className="sello--button" onClick={handleModal}>
              OBTENER EL SELLO AMBIENTAL
            </button>
          </div>
        )}
        <div
          onClick={() => {
            if (step === 1) {
              setStep(2);
            }
            if (step === 2) {
              setStep(1);
            }
          }}
          className="slideArrow--2"
        >
          <FontAwesomeIcon icon={faArrowRight} color="#ffffff" />
        </div>
      </div>
    </div>
  );
}

export default Sello4;
