import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import pic1 from "../../images/sello/pic1-6.webp";
function Sello6({ handleModal }) {
  return (
    <div className="selloAmbientalContainer container--white">
      <div className="selloAmbiental_Sub_Container sello--container--6 ">
        <div className="children--1">
          <img src={pic1} alt="" width={600} height={630} />
        </div>
        <div className="children--2">
          <h2>
            <span className="span--text--green">¿QUÉ ES Y QUÉ HACE</span>
            <span className="title--text--green">
              REFOREST <span className="bolder">ACCIÓN?</span>
            </span>
          </h2>
          <p className="normal--text--black">
            Es una Asociación Civil sin fines de lucro, que busca cuidar y
            restaurar ecosistemas con el fin de reducir el cambio climático y
            garantizar un futuro hermoso para las siguientes generaciones.{" "}
            <br />
            Promueven programas de reforestación, agroforestería y regeneración
            de ecosistemas naturales, trabajando en conjunto con ejidos,
            gobierno, comunidades y dueños de tierras. <br /> Al firmar con
            TRATO, parte de tu suscripción será enviada como donativo a
            ReforestaAcción directamente.
          </p>
          <button className="sello--button" onClick={handleModal}>
            SÉ PARTE DEL CAMBIO{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Sello6;
