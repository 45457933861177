/* global fbq */
import React, { useState, useMemo, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  InputGroupAddon,
  Spinner,
  InputGroup,
  FormFeedback,
  InputGroupText,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import config from "../config";

const DemoFormSchema = Yup.object().shape({
  name: Yup.string().required("Obligatorio"),
  email: Yup.string().email("correo invalido").required("Obligatorio"),
  phone: Yup.string().required("Obligatorio"),
  company: Yup.string().required("Obligatorio"),
  occupation: Yup.string(),
  contractsPerMonth: Yup.number().required("Obligatorio"),
  source: Yup.string().required("Obligatorio"),
  message: Yup.string().required("Obligatorio"),
});

function DemoForm({
  type = "demo",
  formik: { isSubmitting, errors, touched, values, handleChange, handleSubmit },
  formButtonLabel,
}) {
  const { t } = useTranslation("home");
  const [isOpen, setIsOpen] = useState(false);

  function onSubmit() {
    handleSubmit();
    const { email } = values;
    if (type === "landing") {
      fbq(
        "trackSingleCustom",
        config.fbPixels.tratoHomeForm,
        "Registro_Demo_completado_Home",
        { email }
      );
    }
    if (type === "trato-education") {
      fbq(
        "trackSingleCustom",
        config.fbPixels.tratoEducationForm,
        "Registro_Demo_completado_Landing_Edu",
        { email }
      );
    }
  }

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        noValidate
        className="sello--form--container"
      >
        <FormGroup>
          <h3 className="title--text--white">
            Ponte en <br /> contacto
          </h3>
          <Label className="font-weight-bold mb-1" for="name">
            {t("demoForm.name_label")}
            <span className="text-primary">*</span>
          </Label>
          <InputGroup className="mb-1">
            <InputWithLoader
              loading={isSubmitting}
              id="name"
              name="name"
              type="text"
              placeholder={t("demoForm.placeholder1")}
              value={values.name}
              invalid={errors.name && touched.name}
              onChange={handleChange}
            />
            <FormFeedback>{errors.name}</FormFeedback>
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label className="font-weight-bold mb-1" for="email">
            {t("demoForm.email_label")}
            <span className="text-primary">*</span>
          </Label>
          <InputGroup className="mb-1">
            <InputWithLoader
              loading={isSubmitting}
              id="email"
              type="email"
              name="email"
              placeholder={t("demoForm.placeholder2")}
              value={values.email}
              invalid={errors.email && touched.email}
              onChange={handleChange}
            />
            <FormFeedback>{errors.email}</FormFeedback>
          </InputGroup>
        </FormGroup>

        <FormGroup>
          <Label className="font-weight-bold mb-1" for="company">
            {type === "trato-education"
              ? t("demoForm.institution_label")
              : t("demoForm.company_label")}
            <span className="text-primary">*</span>
          </Label>
          <InputGroup className="mb-1">
            <InputWithLoader
              loading={isSubmitting}
              id="company"
              name="company"
              type="text"
              placeholder={
                type === "trato-education"
                  ? t("demoForm.placeholder9")
                  : t("demoForm.placeholder4")
              }
              value={values.company}
              invalid={errors.company && touched.company}
              onChange={handleChange}
            />
            <FormFeedback>{errors.company}</FormFeedback>
          </InputGroup>
        </FormGroup>
        <div className="text-center">
          <button
            type="submit"
            onClick={onSubmit}
            className="button orange animate-up w-100"
            disabled={isSubmitting}
          >
            Formar parte de la iniciativa
          </button>
        </div>
      </Form>
    </>
  );
}

const DemoFormContainer = (props) => {
  const color = props.color != undefined ? "#344e5b" : "#f47b33";
  const {
    buttonLabel = props.text ? props.text : "",
    className,
    type = "demo",
    onlyForm,
    size = "md",
    inverted,
    buttonClassName = "button animate-up mb-2 mr-1 text-center ",
    formButtonLabel,
  } = props;
  const { t } = useTranslation("home");
  const [isOpen, setIsOpen] = useState(false);
  const [succeed, setSucceed] = useState(false);
  const initialValues = useMemo(() => {
    const values = {
      name: "",
      email: "",
      phone: "",
      company: "",
      occupation: "",
      contractsPerMonth: "",
      source: "",
      message: "",
      whyHelp: "Elaborar contrato",
    };
    if (type === "legalAdvisor") {
      values.source = "legalAdvisor";
    }
    if (type === "eSignature") {
      values.source = "eSignature";
    }
    if (type === "demo-500") {
      values.source = "500 startups";
    }
    if (type === "trato-education") {
      values.source = "Landing education";
      values.message = "Short Landing Page Education";
      values.contractsPerMonth = 0;
    }
    if (type === "sello-trato") {
      values.source = "sello-trato";
      values.phone = "-";
      values.message = "Landing sello ambiental";
      values.contractsPerMonth = 0;
    } else {
      values.contractsPerMonth = 0;
    }
    if (type === 'sello-trato') {
      values.source = 'sello-trato';
      values.phone = '-';
      values.message = 'Landing sello ambiental';
      values.contractsPerMonth = 0;
    }
    return values;
  }, [type]);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, actions) => {
      values.type = type;

      const sent = await sendMessage(values);

      trackCustomEvent({
        category: "Solicita una demo",
        action: "Enviar información demo",
        label: values.type,
        value: `${values.name} - ${values.company}`,
      });

      actions.setSubmitting(false);
      actions.resetForm({ ...initialValues });
    },
    validationSchema: DemoFormSchema,
  });

  const toggle = () => {
    formik.resetForm();
    setIsOpen((open) => !open);

    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: "Solicita una demo",
      // string - required - Type of interaction (e.g. 'play')
      action: "Boton landing page",
    });
  };

  const sendMessage = async (values) => {
    const response = await fetch(`${process.env.GATSBY_API_URL}/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    });

    if (response.ok) {
      toast.success(
        "Te enviamos un correo donde podras elegir el horario de la demo",
        {
          position: toast.POSITION.TOP_RIGHT,
          toastId: "success",
        }
      );
      setIsOpen(false);
      // setSucceed(true)
    } else {
      toast.error("Error en el envio.", {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "error",
      });
    }
  };

  if (onlyForm) {
    return (
      <DemoForm type={type} formik={formik} formButtonLabel={formButtonLabel} />
    );
  }

  return (
    <>
      <Button
        className={classnames(`${buttonClassName}`, { inverted })}
        style={{ backgroundColor: color, borderRadius: "10px" }}
        size={size}
        onClick={toggle}
      >
        {" "}
        {buttonLabel || t("demoForm.title")}
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        <ModalHeader className="text-center" toggle={toggle}>
          {t("demoForm.title")}
          <small className="d-block text-muted">
            {t("demoForm.subtitle")}{" "}
          </small>
        </ModalHeader>
        <ModalBody className="py-2 px-5">
          {succeed ? (
            <div>{t("demoForm.succeed")}</div>
          ) : (
            <DemoForm
              type={type}
              formik={formik}
              formButtonLabel={formButtonLabel}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export function InputWithLoader({ loading = false, disabled, ...props }) {
  return (
    <>
      <Input {...props} disabled={disabled || loading} />
      {loading && (
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <Spinner
              type="border"
              color="secondary"
              role="status"
              style={{ borderWidth: "1.5px", width: "1rem", height: "1rem" }}
            />
          </InputGroupText>
        </InputGroupAddon>
      )}
    </>
  );
}

DemoForm.propTypes = {
  type: PropTypes.string,
  formik: PropTypes.shape({
    isSubmitting: PropTypes.bool,
    errors: PropTypes.any,
    touched: PropTypes.any,
    values: PropTypes.any,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.any,
  }),
  formButtonLabel: PropTypes.string,
};

DemoFormContainer.propTypes = {
  buttonLabel: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  onlyForm: PropTypes.bool,
  size: PropTypes.string,
  inverted: PropTypes.any,
  buttonClassName: PropTypes.string,
  formButtonLabel: PropTypes.string,
};

InputWithLoader.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DemoFormContainer;
