import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
function Sello3() {
  return (
    <div className="selloAmbientalContainer container--green">
      <div className="selloAmbiental_Sub_Container sello--container--3">
        <div className="withBorder">
          <span className="title--text--white">220,000</span>
          <p className="normal--text--white">
            Árboles plantados en la república mexicana.
          </p>
        </div>
        <div>
          <span className="title--text--white">100,000</span>
          <p className="normal--text--white">
            Contratos firmados digitalmente.
          </p>
        </div>
        {/* <div>
          <span className="title--text--white">15%</span>
          <p className="normal--text--white">Reducción de C02 de 20 empresas</p>
        </div> */}
      </div>
    </div>
  );
}

export default Sello3;
