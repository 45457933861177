import React, { useEffect, useRef } from "react";
import clsx from 'clsx';
import "../../scss/eFirma/eFirma.scss";
import { graphql, Link, useStaticQuery } from "gatsby";
import Logo from "../../images/Logo Trato.svg";
function NavBar({ className, toggle, isOpen, handleClose, withoutButtons }) {
  // Scroll Listener

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const prevCountRef = useRef();
  const [scrollPosition, setSrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;

    setSrollPosition(position);
  };

  useEffect(() => {
    prevCountRef.current = scrollPosition;
    if (scrollPosition > prevCount) {
      handleClose();
    } else {
      handleClose();
    }
  }, [scrollPosition]);
  const prevCount = prevCountRef.current;

  // Scroll Listener
  return (
    <div className={`eFirma--navBarContainer ${className}`}>
      <div className="eFirma--navBar">
       {
        !withoutButtons &&
        <>
         <div className="navBar--options">
          <Link className="option" to="/">
            Inicio
          </Link>
          <Link className="option" to="/templates">
            Plantillas
          </Link>
          <Link className="option" to="https://blog.trato.io/">
            Blog
          </Link>
        </div>
        {isOpen && (
          <div className="navBar--options--mobile d-sm-none">
            <div
              className={isOpen ? "Menu__container change" : "Menu__container"}
              onClick={toggle}
            >
              <div className="Menu__bar1"></div>
              <div className="Menu__bar2"></div>
              <div className="Menu__bar3"></div>
            </div>
            <Link className="option" to="/">
              Inicio
            </Link>
            <Link className="option" to="/templates">
              Plantillas
            </Link>
            <Link className="option" to="https://blog.trato.io/">
              Blog
            </Link>
          </div>
        )}
          <div
            className={clsx("Menu__container d-sm-none", isOpen && "change")}
            onClick={toggle}
          >
            <div className="Menu__bar1"></div>
            <div className="Menu__bar2"></div>
            <div className="Menu__bar3"></div>
          </div></>
       }
      
        <img
          src={Logo}
          alt=""
          width={160}
          height={60}
          className={"headerTRATOLogo"}
        />
      </div>
    </div>
  );
}

export default NavBar;
