import React from "react";
import pic1 from "../../images/sello/pic1-1.webp";
import pic2 from "../../images/sello/pic2-1.webp";
function Sello1({ handleModal }) {
  return (
    <div className="selloAmbientalContainer container--green--gradient  ">
      <div
        className="selloAmbiental_Sub_Container sello--container--1 "
        style={{ backgroundImage: `URL(${pic1})` }}
      >
        <div className="child--1">
          <p className="span--text--white">
            SELLO DE PROTECCIÓN AMBIENTAL DE TRATO
          </p>
          <h1 className="title--text--white">
            FIRMA ELECTRÓNICAMENTE <br /> Y PROTEGE AL MEDIO AMBIENTE
          </h1>
          <img src={pic2} alt="" width={750} height={590} />
        </div>
        <div className="container--gray child--2">
          <button className="sello--button" onClick={handleModal}>
            DIGITALIZA TUS DOCUMENTOS
          </button>
        </div>
      </div>
    </div>
  );
}

export default Sello1;
