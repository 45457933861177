import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
function Sello2() {
  return (
    <div className="selloAmbientalContainer container--gray--gradient">
      <div className="selloAmbiental_Sub_Container sello--container--2">
        <div className="container--green">
          <p className="span--text--white">LA MISIÓN</p>
          <h2 className="title--text--white">NEUTRALIDAD TECNOLÓGICA</h2>
          <p className="normal--text--white">
            En TRATO, queremos que todas las empresas se preocupen por el medio
            ambiente digitalizando sus procesos tradicionalmente atendidos con
            el uso del papel e incorporar un criterio ambiental en sus políticas
            internas.
          </p>
        </div>
        <div className="grid">
          <div className="child">
            <h2 className="subtitle--text--black">
              REDUCCIÓN DE <br /> Co2
            </h2>
            <p className="normal--text--black">
              México emitió 804 millones de <br /> toneladas de bióxido de
              carbono en <br />
              2020, que representaron cerca del
              <br /> 1.3% de las emisiones globales.
            </p>
          </div>
          <div className="child">
            <h2 className="subtitle--text--black">
              DISMINUCIÓN DE <br /> HUELLA HÍDRICA
            </h2>
            <p className="normal--text--black">
              Se gastan 5 mil litros de agua por <br /> un paquete de 500 hojas,
              y se talan <br /> 17 árboles por tonelada de papel, <br />{" "}
              aproximadamente 1300 paquetes.
            </p>
            <Link to="/" className="link--text--green">
              Firmar electrónicamente
            </Link>
          </div>
          <div className="child">
            <h2 className="subtitle--text--black">
              AHORRO DE <br /> RECURSOS
            </h2>
            <p className="normal--text--black">
              La digitalización de los documentos <br /> y la automatización de
              procesos, <br /> ayudan a las empresas a tener un <br />{" "}
              crecimiento acelerado y a ahorrar <br /> recursos importantes como
              el <br /> papel, dinero y tiempo.
            </p>
          </div>
          <div className="child">
            <h2 className="subtitle--text--black">
              ACCIONES DE <br /> REFORESTACCIÓN
            </h2>
            <p className="normal--text--black">
              Contribuir a{" "}
              <a href="https://reforestaccion.org/" rel="nofollow">
                Reforest<span>Acción</span>
              </a>{" "}
              con <br /> donativos o participando en sus <br /> actividades de
              reforestación.
            </p>
            {/* ReforestAcción ←! A https://reforestaccion.org/ → */}
            <Link
              to="https://reforestaccion.org/?s=contribuir "
              rel="noFollow"
              className="link--text--green"
            >
              Donar a Reforest<span className="font-weight-bold">Acción</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sello2;
