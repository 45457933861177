import { useLayoutEffect, useEffect } from "react";

function useScript({ src, isDisabled = true, locale, onRemove }) {
  useEffect(() => {
    const script = document.createElement("script");
    if (!isDisabled) {
      setTimeout(() => {
        script.setAttribute("src", src);

        script.setAttribute("defer", true);
        
        // eslint-disable-next-line no-unused-expressions
        document.body.append(script);
        console.log("isDisabled", isDisabled);
      }, 300);
    }
    return () => {
      script.remove();
      const leadboosterContainer = document.getElementById(
        "LeadboosterContainer"
      );
      console.log("leadboosterContainer", leadboosterContainer);
      if (leadboosterContainer) {
        leadboosterContainer.remove();
      }
    };
  }, [src, locale, isDisabled]);
}

export default useScript;
