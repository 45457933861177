import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import pic1 from "../../images/sello/picForm.svg";
import DemoForm from "../SelloForm.js";
function FormSection() {
  return (
    <div className="selloAmbientalContainer container--gray">
      <div className="selloAmbiental_Sub_Container sello--container--form">
        <div className="children--1">
          <h2 className="title--text--green">¿CÓMO PARTICIPAR?</h2>
          <p className="normal--text--black">
            Puedes digitalizar todos tus documentos y firmar de manera
            electrónica para evitar y reducir el uso de papel y la contaminación
            de dióxido de carbono, aparte, tomando acción y donando a
            ReforestAcción para que continúen con sus labores de restauración de
            ecosistemas.
            <br />
            <br />
            Promovamos el uso y desarrollo de procesos limpios y sostenibles.
            Forma parte de esta iniciativa promoviendo el sello ambiental,
            aseguremos un futuro sostenible.
          </p>
          <p className="span--text--green">
            FIRMA YA CON TRATO Y AYUDA A REDUCIR EL CAMBIO CLIMÁTICO
          </p>
          <div>
            <img src={pic1} alt="" width={400} height={130} />
          </div>
        </div>
        <div className="children--2">
          <DemoForm onlyForm type={"sello-trato"} />
        </div>
      </div>
    </div>
  );
}

export default FormSection;
