import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import pic1 from "../../images/sello/pic1-8.webp";
function Sello7({ handleModal }) {
  return (
    <div className="selloAmbientalContainer">
      <div className="selloAmbiental_Sub_Container sello--container--7">
        <img src={pic1} alt="" width={760} />

        <div className="sello--container--7--sub">
          <div className="container--green">
            <p className="normal--text--white">
              La digitalización de los documentos y la automatización de
              procesos, ayudan a las empresas a tener un crecimiento acelerado,
              al ahorrar recursos importantes como el papel, dinero y tiempo,
              así como a reducir la huella de carbono. <br /> TRATO, atendiendo
              a estas necesidades, impulsa el mejor desempeño ambiental de las
              empresas y sus colaboradores con el uso del sello ambiental de
              TRATO.
            </p>
          </div>

          <button className="sello--button" onClick={handleModal}>
            OBTENER EL SELLO AMBIENTAL
          </button>
        </div>
      </div>
    </div>
  );
}

export default Sello7;
